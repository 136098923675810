<template>
  <div>
    <Loading :width="40" :height="40" :center="false" v-if="loading" />
    <div v-else>
      <Button :_key="'generateOutput' + keyButton" tooltip="Gerar Saída" color="white" backGroundColor="#009183"
        :title="showTitleButton ? 'Saída' : ''" classIcon="fa-solid fa-circle-arrow-right"
        :size="showTitleButton ? 'medium' : 'small'"
        v-if="statusMovimentStock.reserved - statusMovimentStock.rented > 0" :params="{ routeName: 'generateOutput' }"
        :clicked="navigateTo" />
      <Button :_key="'generateReturn' + keyButton" tooltip="Gerar Retorno" color="white" backGroundColor="#B8860B"
        :title="showTitleButton ? 'Retornar' : ''" classIcon="fa-solid fa-circle-arrow-left"
        :size="showTitleButton ? 'medium' : 'small'"
        v-if="statusMovimentStock.rented - statusMovimentStock.returned > 0" :params="{ routeName: 'generateReturn' }"
        :clicked="navigateTo" />
      <Button :_key="'generateRenewed' + keyButton" tooltip="Gerar Renovação" color="white" backGroundColor="#567EB3"
        :title="showTitleButton ? 'Renovar' : ''" classIcon="fa-solid fa-retweet"
        :size="showTitleButton ? 'medium' : 'small'"
        v-if="statusMovimentStock.rented - statusMovimentStock.returned > 0" :params="{ routeName: 'generateRenewed' }"
        :clicked="navigateTo" />
      <Button :_key="'replacePatrimony' + keyButton" tooltip="Substituição" color="white" backGroundColor="#156EBE"
        :title="showTitleButton ? 'Substituição' : ''" classIcon="fa-sharp fa-solid fa-repeat"
        :size="showTitleButton ? 'medium' : 'small'"
        v-if="statusMovimentStock.rented - statusMovimentStock.returned > 0"
        :params="{ routeName: 'replacementPatrimony' }" :clicked="navigateTo" />
    </div>
  </div>
</template>

<script>
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import Molded from "@nixweb/nixloc-ui/src/component/layout/Molded";
import Loading from "@nixweb/nixloc-ui/src/component/shared/Loading";

import { mapActions, mapMutations } from "vuex";

export default {
  name: "ButtonMovimentStock",
  components: {
    Button,
    Molded,
    Loading
  },
  props: {
    rentId: String,
    periodRentId: String,
    keyButton: {
      type: String,
      default: "",
    },
    showTitleButton: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      urlItemsMovimentTotalization: "/api/v1/stock/items-moviment/totalization",
      statusMovimentStock: {},
      loading: true,
    };
  },
  mounted() {
    this.getStatusMovimentStock();
  },
  methods: {
    ...mapActions("generic", ["getApi"]),
    ...mapMutations("generic", ["removeLoading", "hideModal"]),
    getStatusMovimentStock() {
      this.loading = true;
      let params = {
        url: this.urlItemsMovimentTotalization,
        obj: { rentId: this.rentId, periodRentId: this.periodRentId },
      };
      this.getApi(params).then((response) => {
        this.statusMovimentStock = response.content;

        let self = this;
        setTimeout(function () {
          self.loading = false;
        }, 300);
      });
    },
    navigateTo(params) {
      let self = this;
      setTimeout(function () {
        self.removeLoading([
          "generateOutput" + self.keyButton,
          "generateReturn" + self.keyButton,
          "generateRenewed" + self.keyButton,
          "replacePatrimony" + self.keyButton,
        ]);

        self.hideModal("infoCalendar");

        self.$router.push({
          name: params.routeName,
          params: { id: self.rentId, periodRentId: self.periodRentId },
        });
      }, 300);
    },
  },
};
</script>