<template>
  <div>
    <ScrollBar id="items-rent" :minHeight="0" :maxHeight="150">
      <SelectOptionPeriodRent v-show="!periodRentId" :showPeriodAll="false" :rentId="rentId" v-model="selectedPeriod" />
    </ScrollBar>
    <Loading type="line" :center="false" v-show="loading" />
    <ScrollBar id="items-rent" :minHeight="300" :maxHeight="300">
      <table class="table table-responsive-xs">
        <thead>
          <tr>
            <th>
              <span class="title-header"> Produto </span>
            </th>
            <th class="text-center">
              <span class="title-header">Quantidade</span>
            </th>
          </tr>
        </thead>
        <tbody v-for="(data, groupName) in grouped">
          <td class="group" colspan="8">
            <div>
              <span v-if="groupName" class="title-group">
                {{ groupName }}
              </span>
              <span v-else class="title-group">
                <span class="without-group">Sem grupo</span>
              </span>
            </div>
          </td>
          <tr v-for="item in data">
            <td class="td-product td">
              <span>
                {{ item.productFullName }}
              </span>
              <br />
              <span class="description" v-html="item.description"> </span>
            </td>
            <td class="text-center td-quantity td">
              <div>
                <span> {{ item.quantity }}</span>
                <span v-if="item.type != 3">
                  ({{ item.unitOfMeasurementName }})
                </span>
              </div>
              <div class="quantity">
                <ItemsRentStatusStock v-if="item.type != 3" :data="item" />
              </div>
            </td>
          </tr>
        </tbody>
        <tbody v-show="content.totalRecords == 0">
          <tr>
            <td colspan="12">
              <span class="title">Nenhum registro encontrado!</span>
            </td>
          </tr>
        </tbody>
      </table>
    </ScrollBar>
  </div>
</template>
<script>
import SelectOptionPeriodRent from '../rental/period-rental/SelectOptionPeriodRent.vue'
import ScrollBar from "@nixweb/nixloc-ui/src/component/layout/ScrollBar.vue";
import TableTotalRecords from "@nixweb/nixloc-ui/src/component/shared/TableTotalRecords.vue";
import ItemsRentStatusStock from "../rental/items-rent/ItemsRentStatusStock.vue";
import Loading from "@nixweb/nixloc-ui/src/component/shared/Loading.vue";

import { mapGetters, mapActions } from "vuex";

export default {
  name: "ItemsList",
  props: {
    rentId: String,
    periodRentId: String,
  },
  components: {
    ScrollBar,
    TableTotalRecords,
    ItemsRentStatusStock,
    Loading, SelectOptionPeriodRent
  },
  data() {
    return {
      urlGetApi: "/api/v1/rental/items-rent/get-all",
      data: [],
      item: {},
      selectedPeriod: {},
      loading: true,
      content: {
        data: [],
        totalRecords: 0,
      },
      baseParams: {
        search: undefined,
        filter: "contains",
        currentPage: 1,
        totalPerPage: 20,
      },
    };
  },
  mounted() {
    this.getOptional();
  },
  computed: {
    ...mapGetters("generic", ["groupBy"]),
    ...mapGetters("generic", ["event"]),
    grouped() {
      var list = this.groupBy({
        array: this.content.data,
        key: "groupRentName",
      });
      return list;
    },
  },
  methods: {
    ...mapActions("generic", ["getApi"]),
    getAll(rentId, periodRentId) {
      this.loading = true;
      let params = {
        url: this.urlGetApi,
        obj: {
          ...this.baseParams,
          rentId: rentId,
          periodRentId: periodRentId,
          noPaginated: true,
          withAccessory: true,
        },
      };
      this.getApi(params).then((response) => {
        this.content.data = response.content.data;
        this.content.totalRecords = response.content.totalRecords;
        this.loading = false;
      });
    },
    getOptional() {
      let self = this;
      setTimeout(function () {
        var periodId = self.periodRentId ?? self.selectedPeriod.period.id;
        self.getAll(self.rentId, periodId);
      }, 300);
    }
  },
  watch: {
    rentId: {
      handler() {
        this.getOptional();
      },
      deep: true,
    },
    selectedPeriod: {
      handler() {
        let self = this;
        setTimeout(function () {
          self.getOptional();
        }, 300);
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.table th,
.table td {
  height: 10px !important;
  padding-left: 5px !important;
  padding-top: 7px !important;
  padding-bottom: 5px !important;
  padding-right: 5px !important;
  padding-left: 10px !important;
  border-bottom: 0px !important;
}

.title-header {
  font-size: 14px;
  color: #757d8c;
  font-weight: 400;
  text-transform: uppercase;
}

.group {
  background-color: #fafafc;
  font-size: 14px;
  font-weight: 400;
}

.title-group {
  font-size: 14px;
}

.name-item {
  font-size: 16px;
  margin-bottom: 8px;
}

.div-warning {
  margin-bottom: 20px;
}

.td-product {
  width: 500px;
  background-color: #f1f4f9;
}

.td-quantity {
  width: 150px;
}

.quantity {
  cursor: pointer;
}

.quantity:hover {
  text-decoration: underline;
}

.description {
  font-weight: 400;
  color: grey;
}

.without-group {
  font-size: 13px;
  font-style: italic;
  color: grey;
}

.icon-cursor {
  cursor: pointer;
}

.icon-order-group {
  color: #779cb3;
}

.text-order {
  font-size: 13px;
  margin-left: 10px;
  font-style: italic;
}
</style>
