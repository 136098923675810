<template>
  <div class="container-calendar">
    <div class="container-calendar-item">
      <HorizontalFilter :showTotalPerPage="false">
        <div class="fixed-filter-options div-filter" slot="content-filter-horizontal">
          <Toggle title="Tarefas" :color="'#993300'" v-model="showJob" :changed="changedFilter" />
          <div v-if="this.hasRule('Operational') || this.hasRule('Rental')">
            <Toggle title="Saídas" :color="'#009183'" v-model="showDelivery" :changed="changedFilter" />
            <Toggle title="Locação" :color="'#F1BC31'" v-model="showPeriod" :changed="changedFilter" />
            <Toggle title="Orçamento" :color="'#4BB4E2'" v-model="showProgress" :changed="changedFilter" />
            <Toggle title="Retornos" :color="'#3D4EAE'" v-model="showDevolution" :changed="changedFilter" />
          </div>
          <div class="text-right">
            <CheckboxSimple title="Mostrar realizadas" :markFormDirty="false" v-model="showDone"
              :changed="changedFilter" />
          </div>
        </div>
      </HorizontalFilter>
      <div class="div-legend text-center">
        <div class="side-by-side box-calendar delivery-delay"></div>
        <div class="side-by-side title magin">Saídas em atraso</div>
        <div class="side-by-side box-calendar delivery"></div>
        <div class="side-by-side title magin">Saídas em dia</div>
        <div class="side-by-side box-calendar devolution-delay"></div>
        <div class="side-by-side title magin">Retornos em atraso</div>
        <div class="side-by-side box-calendar devolution"></div>
        <div class="side-by-side title magin">Retornos em dia</div>
        <div class="side-by-side box-calendar rent"></div>
        <div class="side-by-side title magin">Locação</div>
        <div class="side-by-side box-calendar progress-full"></div>
        <div class="side-by-side title">Orçamento</div>
      </div>
      <div class="div-timer text-center">
        <Timer :initialTimer="60" :endTime="endTime" />
      </div>
      <FullCalendar :changedCalendar="changedCalendar" :clickedCalendar="clickedCalendar" :clickedEvent="clickedEvent"
        :loading="loading" />
    </div>
    <Modal :title="titleModal" :width="widthModal" :height="750" v-if="showModal('infoCalendar')"
      :onHideModal="onHideModal">
      <OperationRent module="Calendar" :rentId="rentId" :periodRentId="periodRentId" v-if="rentId"
        v-show="!showPreview" />
    </Modal>
    <Modal title="Tarefa" :width="900" :height="750" v-show="showModal('job')">
      <JobCreateUpdate />
    </Modal>
    <PrintPreview v-if="print" module="Operational" :rentId="rentId" :periodRentId="periodRentId"
      :onHideModal="onHideModal">
      <div slot="top">
        <div class="div-btn-back text-right">
          <Button _key="btnBack" type="info" classIcon="fa-solid fa-arrow-left" size="small" :clicked="back" />
        </div>
      </div>
    </PrintPreview>
  </div>
</template>

<script>

import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import Timer from "@nixweb/nixloc-ui/src/component/shared/Timer";
import FullCalendar from "@nixweb/nixloc-ui/src/component/shared/FullCalendar";
import HorizontalFilter from "@nixweb/nixloc-ui/src/component/shared/HorizontalFilter.vue";
import Toggle from "@nixweb/nixloc-ui/src/component/forms/Toggle";
import CheckboxSimple from "@nixweb/nixloc-ui/src/component/forms/CheckboxSimple.vue";
import Modal from "@nixweb/nixloc-ui/src/component/forms/Modal";

import JobCreateUpdate from '../../../components/modules/shared/job/JobCreateUpdate.vue'
import OperationRent from "../../../components/modules/operational/OperationRent.vue";
import PrintPreview from '../../../components/modules/adm/document/PrintPreview.vue'

import { mapActions, mapMutations, mapGetters, mapState } from "vuex";

export default {
  name: "DashboardView",
  components: {
    Button,
    Timer,
    FullCalendar,
    HorizontalFilter,
    Toggle,
    CheckboxSimple,
    Modal,
    OperationRent,
    JobCreateUpdate,
    PrintPreview
  },
  data() {
    return {
      urlGet: "/api/v1/operational/get-all-calendar",
      urlGetJob: "/api/v1/shared/job/get-all-calendar",
      typeCalendar: "",
      dateStr: "",
      loading: false,
      showJob: true,
      showDelivery: true,
      showPeriod: false,
      showProgress: false,
      showDevolution: true,
      showDone: false,
      titleModal: "",
      widthModal: 900,
      print: false,
      documentId: "",
      rentId: "",
      periodRentId: "",
    };
  },
  mounted() {
    this.getAllByRule();
  },
  computed: {
    ...mapState("user", ["userLogged"]),
    ...mapGetters("user", ["hasRule"]),
    ...mapGetters("generic", ["showModal", "event"]),
  },
  methods: {
    ...mapActions("generic", ["getApi"]),
    ...mapMutations("generic", ["openModal", "addEvent", "removeLoading"]),
    getAllByRule() {
      if (this.hasRule("Operational") || this.hasRule("Rental")) this.getAll();
      this.getJobAll();
    },
    getAll(dateSrt, typeCalendar) {
      this.loading = true;
      let params = {
        url: this.urlGet,
        obj: {
          dateSrt: dateSrt,
          typeCalendar: typeCalendar,
          showDelivery: this.showDelivery,
          showPeriod: this.showPeriod,
          showProgress: this.showProgress,
          showDevolution: this.showDevolution,
          done: this.showDone,
        },
      };
      this.getApi(params).then((response) => {
        this.addEvent({ name: "updateCalendar", data: response.content });
        let self = this;
        setTimeout(function () {
          self.loading = false;
        }, 500);
      });
    },
    getJobAll() {
      this.loading = true;
      let params = {
        url: this.urlGetJob,
        obj: {
          userId: this.userLogged.user.id,
          showJob: this.showJob,
          done: this.showDone,
        },
      };
      this.getApi(params).then((response) => {
        this.addEvent({ name: "updateCalendar", data: response.content });
        let self = this;
        setTimeout(function () {
          self.loading = false;
        }, 500);
      });
    },
    changedFilter() {
      this.addEvent({ name: "cleanCalendar" });
      this.getAllByRule();
    },
    endTime() {
      this.addEvent({ name: "cleanCalendar" });
      this.getAllByRule();
    },
    clickedEvent(args) {
      if (args.extendedProps.source == "operational") {
        this.titleModal = `Locação Nº ${args.extendedProps.numberRent}`;
        this.rentId = args.extendedProps.rentId;
        this.periodRentId = args.extendedProps.periodRentId;
        this.openModal("infoCalendar");
        this.addEvent({
          name: "clickedEventCalendar",
          data: { rentId: this.rentId, periodRentId: this.periodRentId },
        });
      } else {
        this.addEvent({
          name: "jobUpdate",
          data: args.extendedProps.jobResponse,
        });
        this.openModal("job");
      }
    },
    changedCalendar(args) {
      /* 
      Abaixo o código para não fazer o looping e ir toda hora ao servidor
      if (this.typeCalendar != args.view.type) {
        this.getAll(args.startStr, args.view.type);
        this.typeCalendar = args.view.type;
        this.dateStr = args.startStr;
      }
      */
    },
    clickedCalendar(args) {
      const dataString = args.dateStr;
      const parts = dataString.split('-');
      const year = parts[0];
      const month = parts[1];
      const day = parts[2];

      var startEnd = `${day}/${month}/${year}`;

      this.openModal("job");
      this.addEvent({ name: "jobCreate", data: startEnd })
    },
    onHideModal() {
      this.rentId = "";
      this.print = false;
    },
    back() {
      this.print = false;
      this.openModal("infoCalendar");
      this.removeLoading(["btnBack"]);
    }
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "updatedJob") {
          this.addEvent({ name: "cleanCalendar" });
          this.getAllByRule();
        }

        if (event.name == "print") {
          this.print = true;
        }
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.container-calendar {
  margin: auto;
  padding-left: 110px;
  padding-right: 40px;
  max-width: 1300px;
}

.container-calendar-item {
  margin-top: 60px;
  margin-bottom: 40px;
}

.div-filter {
  margin-bottom: 30px;
  padding: 10px;
}

.box-calendar {
  width: 10px;
  height: 10px;
  border-radius: 20px;
  margin-right: 3px;
}

.div-btn-back {
  margin-bottom: 15px;
}

.div-legend {
  margin-top: 15px;
  margin-bottom: 15px;
}

.delivery-delay {
  background-color: red;
}

.delivery {
  background-color: #009183;
}

.devolution {
  background-color: #3d4eae;
}

.devolution-delay {
  background-color: #ff8a1b;
}

.rent {
  background-color: #ffe58f;
}

.progress-full {
  background-color: #4bb4e2;
}

.magin {
  margin-right: 15px;
}

.div-timer {
  margin-bottom: 20px;
}
</style>
