<template>
    <div>
        <Loading type="line" :center="false" v-show="loading" />
        <ScrollBar id="items-rent" :minHeight="300" :maxHeight="300">
            <div v-for="item in content.data">
                <div class="div-molded">
                    <Molded>
                        <b-row>
                            <b-col sm="1">
                                <img :src="'https://espaco.blob.core.windows.net/nixloc-photo-collaborator/' + item.photo"
                                    class="user-photo">
                            </b-col>
                            <b-col sm="6">
                                <b>
                                    <span class="title"> {{ item.fullName }}</span>
                                </b>
                                <div>
                                    <span class="title title-description" v-html="item.description"></span>
                                </div>
                            </b-col>
                            <b-col sm="5">
                                <div class="title div-period">
                                    <i class="fa-solid fa-calendars"></i>
                                    {{ item.fullPeriod }}
                                </div>
                            </b-col>
                        </b-row>
                    </Molded>
                </div>
            </div>
            <div v-if="content.data.length == 0">
                Nenhum registro encontrado!
            </div>
        </ScrollBar>
    </div>
</template>

<script>

import ScrollBar from "@nixweb/nixloc-ui/src/component/layout/ScrollBar.vue";
import TableTotalRecords from "@nixweb/nixloc-ui/src/component/shared/TableTotalRecords.vue";
import Loading from "@nixweb/nixloc-ui/src/component/shared/Loading.vue";
import Molded from "@nixweb/nixloc-ui/src/component/layout/Molded";

import { mapActions } from "vuex";

export default {
    name: "TeamRentList",
    props: {
        rentId: String,
    },
    components: {
        ScrollBar,
        TableTotalRecords,
        Loading,
        Molded
    },
    data() {
        return {
            urlGetApi: "/api/v1/human-resources/team/get-all",
            data: [],
            item: {},
            loading: true,
            content: {
                data: [],
                totalRecords: 0,
            },
            baseParams: {
                search: undefined,
                filter: "contains",
                currentPage: 1,
                totalPerPage: 200,
            },
        };
    },
    mounted() {
        this.getAll(this.rentId);
    },
    methods: {
        ...mapActions("generic", ["getApi"]),
        getAll(rentId) {
            this.loading = true;
            let params = {
                url: this.urlGetApi,
                obj: {
                    ...this.baseParams,
                    any: rentId,
                    noPaginated: true,
                },
            };
            this.getApi(params).then((response) => {
                this.content.data = response.content.data;
                this.content.totalRecords = response.content.totalRecords;
                this.loading = false;
            });
        },
    },
    watch: {
        rentId: {
            handler() {
                this.getAll(this.rentId);
            },
            deep: true,
        },
    },
}


</script>
<style scoped>
.table th,
.table td {
    height: 10px !important;
    padding-left: 5px !important;
    padding-top: 7px !important;
    padding-bottom: 5px !important;
    padding-right: 5px !important;
    padding-left: 10px !important;
    border-bottom: 0px !important;
}

.title-header {
    font-size: 14px;
    color: #757d8c;
    font-weight: 400;
    text-transform: uppercase;
}

.div-description {
    margin-left: 10px;
}

.user-photo {
    width: 45px;
    height: 45px;
    border-radius: 50px;
    margin-bottom: 3px;
    margin-top: 4px;
}

.div-period {
    font-size: 14px;
}

.title-description {
    font-size: 12px;
}

.div-molded {
    margin-bottom: 12px;
}
</style>
